import './import-jquery';
import 'bootstrap';

window.onscroll = function () { setStickyHeader() };
window.onload = function () {
    setStickyHeader();

    $('a.nav-link').on('click', function() {

        if ($(window).width() < 1200){
            $('.navbar-toggler').trigger('click');
        }
    });
};

var navbar = document.getElementById("navbar");
var sticky = navbar.offsetTop + 1;
var navbarH = navbar.offsetHeight;
var sections = [{
        name: 'services',
    },{
        name: 'grayWolfTeam'
    },{
        name: 'contact'
    }
]

function setStickyHeader() {
    if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky")
    } else {
        navbar.classList.remove("sticky");
    }
}